import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  height: "24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M13.038 7.156a2.423 2.423 0 1 0 0-4.846 2.423 2.423 0 0 0 0 4.846Zm5.193 2.769a2.077 2.077 0 1 0 0-4.154 2.077 2.077 0 0 0 0 4.154ZM21 13.732a1.73 1.73 0 1 1-3.462 0 1.73 1.73 0 0 1 3.462 0Zm-4.846 6.578a1.385 1.385 0 1 0 0-2.77 1.385 1.385 0 0 0 0 2.77Zm-4.846 0a1.385 1.385 0 1 1-2.77 0 1.385 1.385 0 0 1 2.77 0ZM4.73 17.54a1.038 1.038 0 1 0 0-2.076 1.038 1.038 0 0 0 0 2.076Zm-.346-6.923a.692.692 0 1 1-1.385 0 .692.692 0 0 1 1.385 0Zm2.77-4.154a.692.692 0 1 0 0-1.384.692.692 0 0 0 0 1.384Z",
      fill: "#040919"
    }, null, -1)
  ])))
}
export default { render: render }